<template>
  <div class="workConsumption">
    <div class="sec_wrap p20">
      <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
        <el-form-item label="作品来源" props="order_source">
          <el-select v-model="params.order_source" placeholder="请选择作品来源" @change="search">
            <el-option v-for="item in source_list" :key="item.value" :label="item.label" :value="item.value"
              :clearable="true">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消耗类型" props="consume_type">
          <el-select v-model="params.consume_type" placeholder="请选择消耗类型" @change="search">
            <el-option v-for="item in consume_type_list" :key="item.value" :label="item.label" :value="item.value"
              :clearable="true">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="search" :clearable="true">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="table_wrap">
        <el-table :data="list" v-loading="loading" style="width: 100%"
          header-cell-class-name="table-header" cell-class-name="cell-class">
          <template slot="empty">
            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
          </template>
          <el-table-column prop="product_name" label="作品名称">
          </el-table-column>
          <el-table-column prop="order_source_text" label="作品来源">
          </el-table-column>
          <el-table-column prop="consume_type_text" label="消耗类型">
          </el-table-column>
          <el-table-column prop="" label="消耗">
            <template slot-scope="scope">
              <div v-if="scope.row.consume_type == 1 || scope.row.consume_type == 2">{{ timesChange(scope.row.product_prop) }}</div>
              <div v-else>{{ scope.row.product_prop }}{{ scope.row.prop_unit }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="剩余">
            <template slot-scope="scope">
              <div v-if="scope.row.consume_type == 1 || scope.row.consume_type == 2">{{ timesChange(scope.row.balance) }}</div>
              <div v-else>{{ scope.row.balance }}{{ scope.row.prop_unit }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="operator_name" label="操作账户">
          </el-table-column>
          <el-table-column prop="pay_time" label="消耗时间">
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="80">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="consumptionDetailsBtn(scope.row)">
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_box">
          <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
            @currentChange='currentChangeBtn'></paging-page>
        </div>
      </div>
    </div>
    <!-- 消耗详情 -->
    <consumption-details ref="consumptionDetails"></consumption-details>
  </div>
</template>
<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import ConsumptionDetails from '../components/consumption_details/consumption_details.vue'//消耗详情
import PagingPage from '@/components/paging_page/paging_page.vue'//分页

export default {
  components: { EmptyData, ConsumptionDetails, PagingPage },
  props: {},
  data() {
    return {
      source_list: [],
      consume_type_list: [],
      params: {},
      date: [],
      page: 1,
      limit: 10,
      total: 0,
      loading: false,
      list: [],
    }
  },
  computed: {
    timesChange() {
      return (val) => {
        return `${this.$utils.formatSeconds(val, 3).h ? this.$utils.formatSeconds(val, 3).h+'小时' : ''}${this.$utils.formatSeconds(val, 3).m ? this.$utils.formatSeconds(val, 3).m+'分钟' : ''}${this.$utils.formatSeconds(val, 3).s}秒`
      }
    }
  },
  watch: {},
  created() {
    this.getParams()
    this.getList()
  },
  mounted() { },
  methods: {
    search() {
      this.page = 1
      this.list = []
      this.total = 0
      this.params.start_date = this.date.length ? this.date[0] : ''
      this.params.end_date = this.date.length ? this.date[1] : ''
      this.getList()
    },
    resetFrom() {
      this.date = []
      this.params.start_date = ''
      this.params.end_date = ''

      this.$refs.params.resetFields()
      this.search()
    },
    sizeChangeBtn(val) {
      this.limit = val
      this.page = 1
      this.getList();
    },
    currentChangeBtn(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      var params = {
        page: this.page,
        limit: this.limit,
        ...this.params
      }
      this.loading = true
      this.$personApi.customerOtherList(params).then(res => {
        this.loading = false
        if (res.code == 1000) {
          this.list = res.result.data
          this.total = res.result.total
        } else {

        }
      })
    },
    consumptionDetailsBtn(row) {
      this.$refs.consumptionDetails.openDialogBtn(row)
    },
    getParams() {
      // 列表查询参数
      this.$personApi.customerOtherParams().then(res => {
        if (res.code == 1000) {
          this.source_list = res.result.order_source
          this.consume_type_list = res.result.consume_type
        } else {
          this.$errMsg(res.message)
        }
      })
    },
  },
}
</script>
<style lang='scss' scoped>
@import '../index.scss';

.workConsumption {}
</style>
